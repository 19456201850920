body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
    margin-top: 100px;
}

#root {
    min-height:100%;
    position:relative;
    padding-bottom: 120px;
}

html {
    margin:0;
    padding:0;
    height:100%;
}

body {
    -webkit-overflow-scrolling: touch;
    padding:0;
    height:100%;
}

div h1 {
    width: 100%;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 15px;
    max-width: 1000px;
    margin: auto;
}

div h1.mw {
    text-align: center;
}

div h2 {
    text-align: left;
    margin-bottom: 15px;
    margin-top: 30px;
}

.row {
    margin: 0 !important;
}

footer {
    background-color: #024358;
    color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 120px;
    overflow: hidden;
}

.footer {
    padding: 30px 0;
    color: rgb(99, 99, 99);
    margin: auto;
    max-width: 1000px;
    color: white;
}

.footer > div {
    display: -webkit-flex;
    display: flex;
    line-height: 1;
}

.footer .text {
    font-weight: 1000;
    color: black;
    margin-right: 10px;
    color: white;
}

.footer a {
    color: rgb(0, 189, 247);
    text-decoration: underline;
}

.footer a:hover {
    /*color: rgb(255, 255, 255);*/
    color: rgb(0, 189, 247);
    text-decoration: none;
}

.loading {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    min-height: 100vh;
}

.form {
    display: -webkit-flex;
    display: flex;
}

.form > button {
    margin-top: 0 !important;
}
    
.logo{
    /* position:absolute; */
    vertical-align: top; 
    top:0px;
    left:0px;
    height: 47px;
    /* margin-top: 13px; */
}

.logo img{
    float: left;
    width: 100%;
    height: auto;
    left: 0px;
}


@media screen and (max-width: 350px) {
    .form {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .form > input {
        margin: auto;
    }

    .form > button {
        margin-top: 0.5rem !important;
        margin: auto;
    }
}


.nav {
    background-color: #024358;
    font-size: 1.2em;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 0 !important;
}

.error { /* Safari */
    transition: height 1000ms;
    height: 0;
    background-color: red;
    border-radius: 0 0 10px 10px;
    margin-top: 65px;
    color: white;
    text-align: center;
    font-weight: 500; 
}

.blueNav {
    z-index: 1029;
    background-color: #024358;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 66px;
    width: 100%;
}

.search-inp {
    width: 150px !important;
}

.error-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    min-height: 80vh;
    color: rgba(211, 211, 211, 0.719);
}

.error-wrapper .big {
    font-size: 80px;
}

.error-wrapper .small {
    font-size: 25px;
}

/*  table results  */

.table-results-buttons {
    padding-top: 5px;
}


.btn-padding {
    float: left;
    margin-left: 20px !important;
}

.row {
    padding: 10px;
}

.result-table .row {
    margin: 0;
    padding: 0;
}

.result-table {
    margin-bottom: 30px;
}

.result-table .card-body {
    padding: 0 0 15px 0;
}

.quadruplex-text {
    letter-spacing: 4px;
}

.grey {
    background-color: rgba(218, 218, 218, 0.678);
}

.purple {
     background-color: rgba(255, 0, 0, 0.493);
}

.green {
    background-color: rgba(1, 255, 1, 0.555);
}

.orange {
    background-color: rgba(255, 181, 43, 0.801); 
}

.btn.getJob-btn:hover {
    background-color: #bbbbbb;
}

.btn.getJob-btn {
    color: rgb(0, 0, 0);
    background-color: rgb(221, 221, 221);
}

.row .card {
    max-width: 1000px;
    margin: auto;
}

.row .card.cite {
    min-width: 800px;
    max-width: 1000px;
}

.row .card.options {
    width: 1000px;
    min-width: 800px;
}

.mw {
    min-width: 800px;
}

.row.mw {
    margin-bottom: 20px !important;
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 300ms;
}

/* exit */
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear;
}
.fade-exit-done {
  opacity: 0;
}

/* help tooltip*/

.help-icon {
    height: 20px;
    float: left;
}

.toolTip {
    background-color: rgb(255, 255, 255); 
    padding: 15px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
}

/* options */

.strands {
    padding-left: 15px;
    padding-top: 5px;
}

.form-control.opt {
    width: 100px;
    padding-left: 20px;
    margin-left: 15px;
}

.btn-padding-opt {
    margin: 10px;
}

h3 {
    margin: 5px;
}

.optsImg {
    width: 100%;
    /* padding-left: 10%; */
    margin-left: 8px;
}

/* dna input */

.buttons .btn-padding-input{
   margin: 5px;
}

.btn.btn-analyze {
    background-color: #024358 !important;
    color: white !important;
    width: 300px;
    font-size: 1.5em;
    margin: auto;
}

.btn-analyze:hover {
    background-color: #138496 !important;
    color: white !important;
}

p.inputText {
    margin: 0;
    text-align: justify; 
}

.input-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
    min-width: 690px;
}

.input-left {
    display: grid;
    grid-row-gap: 20px;
}

.card.input {
    min-width: 800px !important;
}

.buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.buttons form {
    margin: 10px;
}

.pqsText {
    /* margin-top : 20px; */
    text-align: justify;
}

.input-ncbi input{
    margin: 5px 5px 0 0;
}

.extension-label {
    margin-right: 5px;
}
.examples-wrapper {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
}


.help-wrapper {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
}

.question {
    /* border: 1px solid lightgray; */
    /* border-radius: 5px; */
    padding: 20px 0 20px 0;
    /* margin-bottom: 20px; */
}

.h {
    font-weight: bold;
    font-size: 2rem;
}

.optsImg {
    width: 70%;
    margin-top: 20px;
    margin-bottom: 5px;
}

.optsTable {
    margin-bottom: 20px;
}

.optsTable td {
    border: 1px solid #ccc;
    padding: 5px 10px 5px 10px;
}

.optsTable thead td {
    background: #eee;
}

.equation {
    text-align: center;
    font-size: 110%;
}

.flex {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
}

.flex span {
    font-weight: bold;
    margin-right: 4px;
}

.flex p {
    margin-bottom: 0;
}

.contact-wrapper {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
}

span {
    font-weight: bold;
}

.flex {
    display: -webkit-flex;
    display: flex;
}

.body {
    margin-top: 0;
}
.result-table {
    margin: auto;
    max-width: 1200px;
    min-width: 1126px;
    margin-bottom: 30px; 
}

.customRowClass td{
    padding: 6px !important;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.customColumnStyle {
    background-color: #17a2b8;
    color: white;
    text-align: center !important;
}

tr th:first-of-type {
    cursor: pointer;
    padding: 5px 3px 13px 10px;
}

i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}


.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

tr th:first-of-type b{
    margin: auto;
}

tr td{
    cursor: default !important;
}

tr th:first-of-type:hover i{
    border-color: #17a2b8;
}

tr td:first-of-type:hover{
    cursor: pointer !important;
}

tr td:first-of-type:hover i{
    border-color: #17a2b8;
}

.pagTotal {
    margin: 15px;
    text-align: left;
}

.page-item {
    border-width: 1px;
    border-color: rgba(128, 128, 128, 0.555);
    border-radius: 2px;
}

.row {
    width: 100%;
}

.table.table-striped.table-bordered {
    margin: 0;
}

.card-body.result-table-body {
    padding: 0;
}

.pag-showMore {
    margin: 11px 0 15px 0;
}

.pag-select {
    margin-left: 4px;
}

.pagination {
    margin: 15px;
    color: #17a2b8;
    padding: 1px;
    border: 1px solid #17a2b8;
}

.pagination-btn {
    cursor: pointer;
    background-color: white;
    color: #17a2b8;
    border: 2px solid white;
    border-radius: 4px;
    width: 40px;
}

.pagination-btn.text {
    /* color: #024358; */
    width: auto;
}

.pagination-btn:hover {
    /* border: 2px solid #17a2b8 */
    color: #024358;
}

.pagination-btn.disabled {
    color: grey;
}

.pagination-btn.disabled:hover {
    /* border: 2px solid white; */
    cursor: auto;
}

.pagination-btn.active {
    background-color: #17a2b8;
    color: white;
}

.pagination-btn:focus {
    /* border: none; */
    outline: none;
}

.pagination-btn:active {
    border: 2px solid white;
    padding: none;
}

.results-header {
    margin: auto;
    max-width: 1200px;
    min-width: 1126px;
    margin-bottom: 30px;
    border: none !important;
}

.results-header-body {
    /* background-color: #888888ab; */
    /* color: white; */
    border: 2px solid #024358;
    border-radius: 5px;
}

.header-content {
    display: -webkit-flex;
    display: flex;
}

.job-id {
    padding-top: 10px;
    text-align: center;
    margin: 5px 0;
}

.result-table-header {
    border: 2px solid #02425854;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    min-width: 1126px;
}

.card.cite {
    min-width: 1126px;
    max-width: 1200px;
    margin: auto;
}

body.disabled {
    /* overflow: hidden; */
    -webkit-overflow-scrolling: touch;
}

.body {
    overflow: overlay;
    z-index: -10000;
}

body {
    z-index: -10000;
}

.legendSense {
    font-weight: normal;
    color: red;
}
.legendAntisense {
    font-weight: normal;
    color: blue;
}

.graph {
    position: relative;
    height: 630px;
    max-width: 100%;
    margin:auto;
    margin-right: 20px;
    /* overflow: hidden; */
    padding: 15px 15px 0 12px;
}

.graph-wrapper {
    margin: auto;
    max-width: 1200px;
    min-width: 1126px;
    margin-bottom: 30px;
    border: 2px solid #02425854;
    border-radius: 5px;
    padding-right: 20px;
}

.graph::-webkit-scrollbar {
    display: none;
}

h2 {
    text-align: center;
}

.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}
  
.grid path {
    stroke-width: 0;
}

text {
    color: rgb(100, 100, 100);
}
  
.zoom {
    cursor: zoom-in;
    fill: none;
    pointer-events: visible;
    z-index: 10000;
}

.rect {
    /* cursor: pointer; */
    pointer-events: all;
    z-index: 100;
    /* stroke: black; */
}

.detail-wrapper {
    position: relative;
    -webkit-align-content: center;
            align-content: center;
    opacity: 0;
    min-width: 500px;
    max-width: 600px;
    height: 230px;
    z-index: -100;
}

.detail-wrapper.visible {
    opacity: 1;
    z-index: 1000;
}

.detail {
    margin: auto;
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 20px;
    font-size: 1.1em;
    background-color: white;
}

.detail-q {
    margin: auto;
    text-align: left;
    word-wrap: break-word;
}

.detail-info {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.detail-info p {
    text-align: left;
}

.graph-legend {
    /* margin: auto; */
    margin-top: 10px;
    text-align: center;
    font-size: 1.3rem;
}

.graph-legend input {
    margin: 0 4px 0px 10px;
}

.gridLine-x {
    z-index: -50;
    pointer-events: none;
}

.gridLine-y {
    z-index: -50;
    pointer-events: none;
}

.tick {
    font-size: 0.9rem;
}
