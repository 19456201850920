.wrapper {
    margin-top: 100px;
}

#root {
    min-height:100%;
    position:relative;
    padding-bottom: 120px;
}

html {
    margin:0;
    padding:0;
    height:100%;
}

body {
    -webkit-overflow-scrolling: touch;
    padding:0;
    height:100%;
}

div h1 {
    width: 100%;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 15px;
    max-width: 1000px;
    margin: auto;
}

div h1.mw {
    text-align: center;
}

div h2 {
    text-align: left;
    margin-bottom: 15px;
    margin-top: 30px;
}

.row {
    margin: 0 !important;
}

footer {
    background-color: #024358;
    color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 120px;
    overflow: hidden;
}

.footer {
    padding: 30px 0;
    color: rgb(99, 99, 99);
    margin: auto;
    max-width: 1000px;
    color: white;
}

.footer > div {
    display: flex;
    line-height: 1;
}

.footer .text {
    font-weight: 1000;
    color: black;
    margin-right: 10px;
    color: white;
}

.footer a {
    color: rgb(0, 189, 247);
    text-decoration: underline;
}

.footer a:hover {
    /*color: rgb(255, 255, 255);*/
    color: rgb(0, 189, 247);
    text-decoration: none;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.form {
    display: flex;
}

.form > button {
    margin-top: 0 !important;
}
    
.logo{
    /* position:absolute; */
    vertical-align: top; 
    top:0px;
    left:0px;
    height: 47px;
    /* margin-top: 13px; */
}

.logo img{
    float: left;
    width: 100%;
    height: auto;
    left: 0px;
}


@media screen and (max-width: 350px) {
    .form {
        display: flex;
        flex-wrap: wrap;
    }

    .form > input {
        margin: auto;
    }

    .form > button {
        margin-top: 0.5rem !important;
        margin: auto;
    }
}


.nav {
    background-color: #024358;
    font-size: 1.2em;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 0 !important;
}

.error {
    -webkit-transition: height 1000ms; /* Safari */
    transition: height 1000ms;
    height: 0;
    background-color: red;
    border-radius: 0 0 10px 10px;
    margin-top: 65px;
    color: white;
    text-align: center;
    font-weight: 500; 
}

.blueNav {
    z-index: 1029;
    background-color: #024358;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 66px;
    width: 100%;
}

.search-inp {
    width: 150px !important;
}

.error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
    color: rgba(211, 211, 211, 0.719);
}

.error-wrapper .big {
    font-size: 80px;
}

.error-wrapper .small {
    font-size: 25px;
}

/*  table results  */

.table-results-buttons {
    padding-top: 5px;
}


.btn-padding {
    float: left;
    margin-left: 20px !important;
}

.row {
    padding: 10px;
}

.result-table .row {
    margin: 0;
    padding: 0;
}

.result-table {
    margin-bottom: 30px;
}

.result-table .card-body {
    padding: 0 0 15px 0;
}

.quadruplex-text {
    letter-spacing: 4px;
}

.grey {
    background-color: rgba(218, 218, 218, 0.678);
}

.purple {
     background-color: rgba(255, 0, 0, 0.493);
}

.green {
    background-color: rgba(1, 255, 1, 0.555);
}

.orange {
    background-color: rgba(255, 181, 43, 0.801); 
}

.btn.getJob-btn:hover {
    background-color: #bbbbbb;
}

.btn.getJob-btn {
    color: rgb(0, 0, 0);
    background-color: rgb(221, 221, 221);
}
