body.disabled {
    /* overflow: hidden; */
    -webkit-overflow-scrolling: touch;
}

.body {
    overflow: overlay;
    z-index: -10000;
}

body {
    z-index: -10000;
}

.legendSense {
    font-weight: normal;
    color: red;
}
.legendAntisense {
    font-weight: normal;
    color: blue;
}

.graph {
    position: relative;
    height: 630px;
    max-width: 100%;
    margin:auto;
    margin-right: 20px;
    /* overflow: hidden; */
    padding: 15px 15px 0 12px;
}

.graph-wrapper {
    margin: auto;
    max-width: 1200px;
    min-width: 1126px;
    margin-bottom: 30px;
    border: 2px solid #02425854;
    border-radius: 5px;
    padding-right: 20px;
}

.graph::-webkit-scrollbar {
    display: none;
}

h2 {
    text-align: center;
}

.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}
  
.grid path {
    stroke-width: 0;
}

text {
    color: rgb(100, 100, 100);
}
  
.zoom {
    cursor: zoom-in;
    fill: none;
    pointer-events: visible;
    z-index: 10000;
}

.rect {
    /* cursor: pointer; */
    pointer-events: all;
    z-index: 100;
    /* stroke: black; */
}

.detail-wrapper {
    position: relative;
    align-content: center;
    opacity: 0;
    min-width: 500px;
    max-width: 600px;
    height: 230px;
    z-index: -100;
}

.detail-wrapper.visible {
    opacity: 1;
    z-index: 1000;
}

.detail {
    margin: auto;
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 20px;
    font-size: 1.1em;
    background-color: white;
}

.detail-q {
    margin: auto;
    text-align: left;
    word-wrap: break-word;
}

.detail-info {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.detail-info p {
    text-align: left;
}

.graph-legend {
    /* margin: auto; */
    margin-top: 10px;
    text-align: center;
    font-size: 1.3rem;
}

.graph-legend input {
    margin: 0 4px 0px 10px;
}

.gridLine-x {
    z-index: -50;
    pointer-events: none;
}

.gridLine-y {
    z-index: -50;
    pointer-events: none;
}

.tick {
    font-size: 0.9rem;
}