.result-table {
    margin: auto;
    max-width: 1200px;
    min-width: 1126px;
    margin-bottom: 30px; 
}

.customRowClass td{
    padding: 6px !important;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.customColumnStyle {
    background-color: #17a2b8;
    color: white;
    text-align: center !important;
}

tr th:first-of-type {
    cursor: pointer;
    padding: 5px 3px 13px 10px;
}

i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}


.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

tr th:first-of-type b{
    margin: auto;
}

tr td{
    cursor: default !important;
}

tr th:first-of-type:hover i{
    border-color: #17a2b8;
}

tr td:first-of-type:hover{
    cursor: pointer !important;
}

tr td:first-of-type:hover i{
    border-color: #17a2b8;
}

.pagTotal {
    margin: 15px;
    text-align: left;
}

.page-item {
    border-width: 1px;
    border-color: rgba(128, 128, 128, 0.555);
    border-radius: 2px;
}

.row {
    width: 100%;
}

.table.table-striped.table-bordered {
    margin: 0;
}

.card-body.result-table-body {
    padding: 0;
}

.pag-showMore {
    margin: 11px 0 15px 0;
}

.pag-select {
    margin-left: 4px;
}

.pagination {
    margin: 15px;
    color: #17a2b8;
    padding: 1px;
    border: 1px solid #17a2b8;
}

.pagination-btn {
    cursor: pointer;
    background-color: white;
    color: #17a2b8;
    border: 2px solid white;
    border-radius: 4px;
    width: 40px;
}

.pagination-btn.text {
    /* color: #024358; */
    width: auto;
}

.pagination-btn:hover {
    /* border: 2px solid #17a2b8 */
    color: #024358;
}

.pagination-btn.disabled {
    color: grey;
}

.pagination-btn.disabled:hover {
    /* border: 2px solid white; */
    cursor: auto;
}

.pagination-btn.active {
    background-color: #17a2b8;
    color: white;
}

.pagination-btn:focus {
    /* border: none; */
    outline: none;
}

.pagination-btn:active {
    border: 2px solid white;
    padding: none;
}

.results-header {
    margin: auto;
    max-width: 1200px;
    min-width: 1126px;
    margin-bottom: 30px;
    border: none !important;
}

.results-header-body {
    /* background-color: #888888ab; */
    /* color: white; */
    border: 2px solid #024358;
    border-radius: 5px;
}

.header-content {
    display: flex;
}

.job-id {
    padding-top: 10px;
    text-align: center;
    margin: 5px 0;
}

.result-table-header {
    border: 2px solid #02425854;
    border-radius: 5px;
    display: flex;
    min-width: 1126px;
}

.card.cite {
    min-width: 1126px;
    max-width: 1200px;
    margin: auto;
}
