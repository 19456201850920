
.help-wrapper {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
}

.question {
    /* border: 1px solid lightgray; */
    /* border-radius: 5px; */
    padding: 20px 0 20px 0;
    /* margin-bottom: 20px; */
}

.h {
    font-weight: bold;
    font-size: 2rem;
}

.optsImg {
    width: 70%;
    margin-top: 20px;
    margin-bottom: 5px;
}

.optsTable {
    margin-bottom: 20px;
}

.optsTable td {
    border: 1px solid #ccc;
    padding: 5px 10px 5px 10px;
}

.optsTable thead td {
    background: #eee;
}

.equation {
    text-align: center;
    font-size: 110%;
}

.flex {
    display: flex;
    margin-bottom: 10px;
}

.flex span {
    font-weight: bold;
    margin-right: 4px;
}

.flex p {
    margin-bottom: 0;
}
