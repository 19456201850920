.contact-wrapper {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
}

span {
    font-weight: bold;
}

.flex {
    display: flex;
}

.body {
    margin-top: 0;
}