.row .card {
    max-width: 1000px;
    margin: auto;
}

.row .card.cite {
    min-width: 800px;
    max-width: 1000px;
}

.row .card.options {
    width: 1000px;
    min-width: 800px;
}

.mw {
    min-width: 800px;
}

.row.mw {
    margin-bottom: 20px !important;
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 300ms;
}

/* exit */
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear;
}
.fade-exit-done {
  opacity: 0;
}

/* help tooltip*/

.help-icon {
    height: 20px;
    float: left;
}

.toolTip {
    background-color: rgb(255, 255, 255); 
    padding: 15px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
}

/* options */

.strands {
    padding-left: 15px;
    padding-top: 5px;
}

.form-control.opt {
    width: 100px;
    padding-left: 20px;
    margin-left: 15px;
}

.btn-padding-opt {
    margin: 10px;
}

h3 {
    margin: 5px;
}

.optsImg {
    width: 100%;
    /* padding-left: 10%; */
    margin-left: 8px;
}

/* dna input */

.buttons .btn-padding-input{
   margin: 5px;
}

.btn.btn-analyze {
    background-color: #024358 !important;
    color: white !important;
    width: 300px;
    font-size: 1.5em;
    margin: auto;
}

.btn-analyze:hover {
    background-color: #138496 !important;
    color: white !important;
}

p.inputText {
    margin: 0;
    text-align: justify; 
}

.input-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
    min-width: 690px;
}

.input-left {
    display: grid;
    grid-row-gap: 20px;
}

.card.input {
    min-width: 800px !important;
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.buttons form {
    margin: 10px;
}

.pqsText {
    /* margin-top : 20px; */
    text-align: justify;
}

.input-ncbi input{
    margin: 5px 5px 0 0;
}

.extension-label {
    margin-right: 5px;
}